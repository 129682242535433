.App {
  margin-top: 15px;
}

.App .navbar-brand {
  font-weight: bold;
}

/* to fix as per stack overflow : Link cannot appear as a descendant of a link
  I needed to add the span componentClass, but that messed up formatting */
.navbar-nav span[role='button'] {
  padding: 15px;
  display: inline-block;
  line-height: 20px;
}

.navbar-cerulean {
  color: blueviolet;
  background-color: #0272bc;
}

.navbar-cerulean:hover {
  color: greenyellow !important;
}

.color-navbar {
  color: blueviolet;
  background-color: #0272bc;
}

.color-navbar:hover {
  background-color: greenyellow;
}

/* this is to use id as a higher precedence, to get over the bootstrap .navbar-text that was overriding the value*/
#color-text {
  color: white;
}

a .navbar-brand .navbar-nav .nav-link .navbar-text {
  color: white;
}

a:hover .navbar-brand:hover .navbar-nav:hover .nav-link:hover {
  color: lightblue;
}

:root {
  --amplify-primary-color:#2969B1; /*#015388;  */
  --amplify-primary-tint: #0272bc;
  --amplify-primary-shade: #3d93cc;
}

/* This is the required body padding for the fixed navbar so it does not overlay with other content
Make sure to include this after the core Bootstrap CSS.*/

body {
  padding-top: 61px;
}

/*
https://stackoverflow.com/questions/11124777/twitter-bootstrap-navbar-fixed-top-overlapping-site#:~:text=Fixed%20navbars%20use%20position%3A%20fixed,prevent%20overlap%20with%20other%20elements.&text=As%20others%20have%20stated%20adding,top%20to%20body%20works%20great.
As others have stated adding a padding-top to body works great. But when you make the screen narrower (to cell phone widths) there is a gap between the navbar and the body. Also, a crowded navbar can wrap to a multi-line bar, overwriting some of the content again.

This solved these kinds of issues for me

body { padding-top: 40px; }
@media screen and (max-width: 768px) {
    body { padding-top: 0px; }
}
This makes a 40px padding by default and 0px when under 768px width (which according to bootstrap's docs is the cell phone layout cutoff where the gap would be created)

*/
